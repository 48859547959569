.logo {
  height: 32px;
  background: url("timbro-contracting-logo.png");
  background-size: contain;
}

.site-layout-sub-header-background {
  background: #fff;
}

.site-layout-background {
  background: #fff;
}

.ant-segmented-item {
  margin-bottom: 0 !important;
}

.ant-menu-inline .ant-menu-item,
.ant-menu-inline .ant-menu-submenu-title {
  width: unset;
}

@media only screen and (min-width: 992px) {
  #mobileNavigation {
    display: none;
  }
}

@media only screen and (max-width: 992px) {
  .ant-layout-sider-zero-width-trigger-left {
    display: none;
  }
}

.ant5-radio-group.ant5-radio-group-outline.ant5-picker-calendar-mode-switch {
  display: none;
}

.ant-notification-notice {
  margin-bottom: unset;
}

.row-bg-warn {
  background-color: #ffe7ba
}

.row-bg-error {
  background-color: #ffccc7
}